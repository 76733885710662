<template>
    <div>
        <TinyNavigation :prev="{name: 'review.show', params: {id: id}}" class="pt-6 px-6" />

        <template v-if="!review_not_found">
            <div class="px-6 pt-10">
                <h1 class="heading-1 pb-4 whitespace-pre-line" v-text="t('general_review')"></h1>
            </div>

            <div v-for="(question, index) in questions" :key="index" class="text-white odd:bg-gray-900 w-full px-6 py-5">
                <div class="flex items-center justify-between">
                <h6 class="font-semibold pb-4">{{ question.title }}</h6>
                <h6 class="font-semibold pb-4 text-gray-300">{{ question.skill }}</h6>
                </div>
                <Bar v-for="(option, index) in question.options" :key="index" :value="option.value" :label="option.title" />
            </div>

        </template>
        <template v-else>
            <p class="text-white px-12 pt-10">{{ t('review_not_found') }}</p>
        </template>
    </div>
</template>

<script>
import TinyNavigation from "@/components/TinyNavigation";
import { getReview } from "@/helpers/api/ReviewHelper";
import messages from "@/helpers/storage/MessageHelper";
import Bar from "@/components/statistics/Bar";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ReviewShowGeneralPoints",
    setup() {
        return useI18n({
            viewPrefix: "ReviewShowGeneralPoints"
        });
    },
    components: { TinyNavigation, Bar },
    props: ['id'],
    created(){
        getReview(this.id)
            .then((data) => {
                this.questions = data.questions[0].questions;
            })
            .catch(err => {
                if (err.status === 404 || err.status === 403){
                    this.review_not_found = true;
                } else {
                    messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                }
            });
    },
    data(){
        return {
            review_not_found: false,
            questions: [],
        }
    },
    methods: {
        bar_color: function(val) {
            if (val <= 40){
                return 'background-red';
            }
            if (val <= 65){
                return 'background-orange';
            }
            return 'background-green';
        }
    },
}
</script>
<style scoped>
.background-red {
  background-color: #A62130;
}

.background-orange {
  background-color: #f56d2c;
}

.background-green {
  background-color: #5CB824;
}
</style>